window.addEventListener("load", function () {
  var headerSliderItems = document.querySelectorAll(".header-slider__carousel");
  var formationTypes = document.querySelectorAll(".formation-types__carousel");
  var formationSectors = document.querySelectorAll(".formation-sectors__carousel");
  if (headerSliderItems) {
    var headerSlider = new Swiper(".header-slider__carousel", {
      direction: "horizontal",
      loop: true,
      slidesPerView: 1,
      autoHeight: true,
      pagination: {
        el: ".header-slider__carousel__navigation .swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".header-slider__carousel__arrows .swiper-button-next",
        prevEl: ".header-slider__carousel__arrows .swiper-button-prev"
      },
      a11y: {
        prevSlideMessage: "Diapositive précédente",
        nextSlideMessage: "Diapositive suivante"
      }
    });
  }
  if (formationTypes) {
    var formationTypesCarousel = new Swiper(".formation-types__carousel", {
      direction: "horizontal",
      loop: false,
      slidesPerView: "auto",
      navigation: {
        nextEl: ".formation-types__carousel__navigation .swiper-button-next",
        prevEl: ".formation-types__carousel__navigation .swiper-button-prev"
      },
      a11y: {
        prevSlideMessage: "Filtres précédents",
        nextSlideMessage: "Filtres suivants"
      }
    });
  }
  if (formationSectors) {
    var formationSectorsCarousel = new Swiper(".formation-sectors__carousel", {
      direction: "horizontal",
      loop: false,
      slidesPerView: "auto",
      navigation: {
        nextEl: ".formation-sectors__carousel__navigation .swiper-button-next",
        prevEl: ".formation-sectors__carousel__navigation .swiper-button-prev"
      },
      a11y: {
        prevSlideMessage: "Filtres précédents",
        nextSlideMessage: "Filtres suivants"
      }
    });
  }
  jQuery(document).ready(function ($) {
    // Function to hide the dropdown
    function hideDropdown(target) {
      $(target).hide();
      // You can also change the button text or state here if needed
      // e.g., $('.dropdown-toggle').text('Show Options');
    }

    // Toggle dropdown visibility when the button is clicked
    $(".formation-sectors, .formation-types").click(function () {
      var target = $(this).data("target");
      // Hide any other open dropdowns
      $(".dropdown-menu").not(target).hide();
      // Toggle the clicked dropdown
      $(target).toggle();
    });

    // Detect click outside the dropdown and hide it
    $(document).mouseup(function (e) {
      $(".dropdown-menu").each(function () {
        // Check if the clicked area is not the dropdown and not a descendant of the dropdown
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && !$(e.target).closest(".search-form__filter-wrapper").length && !$(e.target).closest(".dropdown-toggle").length) {
          hideDropdown(this);
        }
      });
    });
  });
  // Function to update the display of selected choices
  function updateSelectedChoices() {
    var selectedSectors = [];
    // Find all checked checkboxes within the formation-sectors wrapper
    $('#sectors-dropdown input[type="checkbox"]:checked, #types-dropdown input[type="checkbox"]:checked').each(function () {
      var label = $('label[for="' + this.id + '"]').text().replace(/ \(\d+\)/, "");
      selectedSectors.push(label);
    });

    // Clear the current selected choices
    $("#selected-filters").empty();

    // Append the selected choices to the container
    $.each(selectedSectors, function (i, sector) {
      $("#selected-filters").append('<div class="selected-option">' + sector + '<span class="remove-option">X</span></div>');
    });

    // Show or hide the "Clear All" button based on the number of selected options
    if (selectedSectors.length > 1) {
      $("#selected-filters").append('<div class="clear-all">Supprimer tout</div>');
    }

    // Add click event to remove-option span to uncheck the checkbox and update the list
    $(".remove-option").click(function () {
      var optionText = $(this).parent().text().slice(0, -1); // Remove the 'X' from the text
      $('#sectors-dropdown input[type="checkbox"], #types-dropdown input[type="checkbox"]').each(function () {
        if ($('label[for="' + this.id + '"]').text().replace(/ \(\d+\)/, "") === optionText) {
          $(this).prop("checked", false); // Uncheck the checkbox
          updateSelectedChoices(); // Update the selected choices display
        }
      });
    });

    // Add click event to "Clear All" button to uncheck all checkboxes and update the list
    $(".clear-all").click(function () {
      $('#sectors-dropdown input[type="checkbox"]:checked, #types-dropdown input[type="checkbox"]:checked').prop("checked", false);
      updateSelectedChoices(); // Update the selected choices display
    });
  }

  // Listen for changes on checkboxes within the formation-sectors wrapper
  $('#sectors-dropdown input[type="checkbox"], #types-dropdown input[type="checkbox"]').change(function () {
    updateSelectedChoices();
  });

  // Initial update in case some checkboxes are already checked on page load
  updateSelectedChoices();

  // Listen for changes on checkboxes within the formation-sectors wrapper
  $('#sectors-dropdown input[type="checkbox"], #types-dropdown input[type="checkbox"]').change(function () {
    updateSelectedChoices();
  });
  // Listen for a click event on the #toggle_finance element
  $("#toggle_finance").click(function () {
    // Retrieve the checkbox within the #toggle_finance container
    var checkbox = $(this).find(".toggle_input");

    // Check or uncheck the checkbox
    checkbox.prop("checked", !checkbox.prop("checked"));

    // Trigger the change event on the checkbox to update any other related functionality
    checkbox.change();
  });
  // Listen for a click event on the .filter__checkbox element
  $(".filter__checkbox").click(function (event) {
    // Prevent the event from bubbling up to the parent elements
    event.stopPropagation();

    // Get the checkbox input field inside the .filter__checkbox element
    var checkboxInput = $(this).find('input[type="checkbox"]');

    // Get the associated label for the checkbox input
    var label = $(this).find("label");

    // Check if the label has the 'disabled-dropdown-item' class
    if (!label.hasClass("disabled-dropdown-item")) {
      // If the label does not have the disabled class, toggle the checked state of the checkbox input
      checkboxInput.prop("checked", !checkboxInput.prop("checked"));

      // Trigger the change event on the checkbox to update any other related functionality
      checkboxInput.change();
    }
  });

  // Prevent the checkbox from toggling twice when the label or input is clicked directly
  $('.filter__checkbox input[type="checkbox"], .filter__checkbox label').click(function (event) {
    event.stopPropagation();
  });
});